import CompModel from "@@/digital/detail/model.vue";
import CompTech from "@@/digital/detail/tech.vue";
import { mapState, mapActions } from 'vuex';
import { mdToast } from "../../../utils/tools";
export default {
  components: {
    CompModel,
    CompTech
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl
    }),
    isIos() {
      return /ipad|ipod|iphone/.test(navigator.userAgent.toLowerCase());
    },
    isAndroid() {
      return /android/.test(navigator.userAgent.toLowerCase());
    }
  },
  data() {
    return {
      glb_url: '',
      usdz_url: '',
      detail: {
        nft_type: 2
      }
    };
  },
  async mounted() {
    let {
      glb_url,
      usdz_url
    } = this.$route.query;
    if (glb_url || usdz_url) {
      if (glb_url) {
        this.glb_url = glb_url;
      }
      if (usdz_url) {
        this.usdz_url = usdz_url;
      }
    } else {
      mdToast("未获取到模型链接");
    }
  },
  methods: {
    ...mapActions(['currentlLinkUrlAction']),
    async goToLink() {
      let url = '';
      if (this.isIos && this.usdz_url) {
        url = this.usdz_url;
      } else if (this.isAndroid && this.glb_url) {
        url = this.glb_url;
      }
      if (url) {
        // 获取linkme链接
        const config = {
          type: 'nft_detail',
          nft_type: '2',
          url: url
        };
        await this.currentlLinkUrlAction(config);
        location.href = this.linkUrl;
      }
    }
  }
};